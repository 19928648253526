import { Controller } from "stimulus";
import "bootstrap";
// import $ from "jquery";

// require("select2/dist/css/select2");
// require("select2-bootstrap-theme/dist/select2-bootstrap");

import Select2 from "select2";

export default class extends Controller {
  connect() {
    flatpickr(".datepicker:not(.flatpickr-input)", {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      locale: "it",
    });
  }

  disconnect() {}
}
