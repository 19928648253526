import { Controller } from "stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "vatId",
    "companyId",
    "isMemberTrue",
    "isMemberFalse",
    "companyData",
    "modifyCompanyAction",
  ];

  connect() {
    this.load();

    $(".search-select").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
  }

  load() {
    const companyId = this.companyIdTarget.value;
    if (companyId && companyId != "") {
      axios
        .get("/admin/companies/" + this.companyIdTarget.value + ".json")
        .then((response) => {
          this.vatIdTarget.value = response.data.vat_id;
          this.isMemberTrueTarget.checked = response.data.is_member;
          this.isMemberFalseTarget.checked = !response.data.is_member;
          this.companyDataTarget.classList.remove("d-none");
          this.modifyCompanyActionTarget.classList.remove("d-none");
          this.modifyCompanyActionTarget.href =
            "/admin/companies/" + response.data.id + "/edit";
        });
    }
  }
}
