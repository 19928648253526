import { Controller } from "stimulus";
import $ from "jquery";
import axios from "axios";

export default class extends Controller {
  static targets = ["typology", "additionalFields", "typologyCreate", "price"];

  connect() {
    $("#stimulus-course-form select").on("select2:select", function() {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    let typology = this.typologyTarget.value;
    if (typology != "") {
      this.additionalFieldsTarget.classList.remove("d-none");
      this.typologyCreateTarget.classList.add("d-none");
    } else {
      this.additionalFieldsTarget.classList.add("d-none");
      this.typologyCreateTarget.classList.remove("d-none");
    }
  }

  typologyChange() {
    let typology = this.typologyTarget.value;
    if (typology != "") {
      if (this.hasPriceTarget) {
        axios
          .get("/admin/typologies/" + typology + ".json")
          .then((response) => {
            this.priceTarget.value = response.data.price;
          });
      }
      this.additionalFieldsTarget.classList.remove("d-none");
      this.typologyCreateTarget.classList.add("d-none");
    } else {
      this.additionalFieldsTarget.classList.add("d-none");
      this.typologyCreateTarget.classList.remove("d-none");
    }
  }
}
