import Vue from "vue/dist/vue.esm";

Vue.directive("select2", {
  inserted(el) {
    $(el).on("select2:select", () => {
      const event = new Event("change", { bubbles: true, cancelable: true });
      el.dispatchEvent(event);
    });

    $(el).on("select2:unselect", () => {
      const event = new Event("change", { bubbles: true, cancelable: true });
      el.dispatchEvent(event);
    });
  },
});
