import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = [
    "isMemberTrue",
    "isMemberFalse",
    "memberType",
    "atecoPart1",
    "atecoPart2",
    "atecoPart3",
    "atecoCode",
  ];

  connect() {
    this.memberTypeChange();
  }

  membershipChange() {
    let isMember = this.isMemberTrueTarget.checked;
    if (isMember == true || isMember == "true") {
      $(".membership-branch-container").fadeIn();
    } else {
      $(".membership-branch-container").fadeOut();
    }
  }

  memberTypeChange() {
    if (this.hasMemberTypeTarget) {
      let memberType = this.memberTypeTarget.value;

      if (memberType == "Azienda associata a Confartigianato") {
        this.isMemberTrueTarget.checked = true;
      } else {
        this.isMemberFalseTarget.checked = true;
      }

      if (memberType == "") {
        $(".individual-fields-container").fadeOut();
        $(".company-fields-container").fadeOut();
      } else if (
        memberType == "Azienda associata a Confartigianato" ||
        memberType == "Azienda non associata a Confartigianato"
      ) {
        $(".individual-fields-container").fadeOut();
        $(".company-fields-container").fadeIn();
      } else {
        $(".company-fields-container").fadeOut();
        $(".individual-fields-container").fadeIn();
      }
    }
    this.membershipChange();
  }

  atecoUpdate() {
    let atecoPart1 = this.atecoPart1Target.value;
    let atecoPart2 = this.atecoPart2Target.value;
    let atecoPart3 = this.atecoPart3Target.value;

    this.atecoCodeTarget.value =
      atecoPart1 + "." + atecoPart2 + "." + atecoPart3;
  }
}
