import { Controller } from "stimulus";
import "bootstrap";
// import $ from "jquery";

// require("select2/dist/css/select2");
// require("select2-bootstrap-theme/dist/select2-bootstrap");

import Select2 from "select2";

export default class extends Controller {
  connect() {
    flatpickr(".datetimepicker-stimulus:not(.flatpickr-input)", {
      altInput: true,
      altFormat: "d/m/Y H:i",
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      locale: "it",
      time_24hr: true,
    });
  }

  disconnect() {}
}
