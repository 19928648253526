import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["companyId"];

  connect() {
    // $(".search-select").select2();

    $(".nested-fields .submit-user-on-change").on("change", function(event) {
      $("#pre-cart").val("true");
      $(this)
        .closest("form")
        .submit();
    });
  }

  // updateCompany() {
  //   let companyId = this.companyIdTarget.value;
  //   Turbo.visit(window.location.pathname + "?company_id=" + companyId);
  // }
}
