import { Controller } from "stimulus";
import axios from "axios";
import videojs from "video.js";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "activePlayer",
    "videoDebug",
    "currentTime",
    "apiUpdate",
    "attentionAlert",
  ];
  interval = null;
  alertInterval = null;
  alertTimeout = null;

  connect() {
    if (this.hasActivePlayerTarget) {
      var video = videojs(this.activePlayerTarget);
      video.ready(() => {
        // progress interval
        if (this.data.has("completed")) {
          var completed = this.data.get("completed");
        }
        if (this.data.has("startAt")) {
          if (completed == "false") {
            video.currentTime(this.data.get("startAt"));
          }
        }
        this.interval = setInterval(() => {
          var currentTime = parseInt(video.currentTime());
          if (currentTime > 0) {
            this.updateAttendee(currentTime);
          }
          if (this.hasCurrentTimeTarget) {
            this.currentTimeTarget.innerHTML = currentTime;
          }
        }, 5000);
        // alert interval
        if (this.data.has("alertInterval")) {
          var alertIntervalSeconds = this.data.get("alertInterval");
        } else {
          var alertIntervalSeconds = 60;
        }
        this.alertInterval = setInterval(() => {
          this.sendAlert(video);
        }, alertIntervalSeconds * 1000);
      });
      video.on("ended", () => {
        if (this.interval) {
          window.clearInterval(this.interval);
        }
        if (this.alertInterval) {
          window.clearInterval(this.alertInterval);
        }
        var currentTime = parseInt(video.currentTime());
        this.updateAttendee(currentTime, true);
        if (this.hasCurrentTimeTarget) {
          this.currentTimeTarget.innerHTML = "ended";
        }
      });
    }
  }

  disconnect() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
    if (this.alertInterval) {
      window.clearInterval(this.alertInterval);
    }
  }

  sendAlert(video) {
    if (this.data.has("completed")) {
      var completed = this.data.get("completed");
    }
    if (completed == "false") {
      this.alertTimeout = window.setTimeout(function () {
        video.pause();
      }, 5 * 1000);
      video.exitFullscreen();
      this.attentionAlertTarget.classList.remove("hide");
    }
  }

  attentionConfirmation() {
    window.clearTimeout(this.alertTimeout);
    this.attentionAlertTarget.classList.add("hide");
    var video = videojs(this.activePlayerTarget);
    video.play();
  }

  updateAttendee(currentTime, complete = false) {
    var attendeeId = this.data.get("attendeeId");
    var courseId = this.data.get("courseId");
    if (attendeeId && attendeeId != "") {
      const token = document.querySelector("[name=csrf-token]").content;

      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios
        .post("/course/video/attendee/update", {
          id: courseId,
          attendee: attendeeId,
          complete: complete,
          current_timestamp: currentTime,
        })
        .then((response) => {
          if (this.hasApiUpdateTarget) {
            this.apiUpdateTarget.innerHTML = "ok " + this.getDateTime();
          }
          if (complete) {
            var completedUrl = this.data.get("completedUrl");
            if (completedUrl) {
              window.location.href = completedUrl;
            }
          }
        })
        .catch((error) => {
          if (this.hasApiUpdateTarget) {
            this.apiUpdateTarget.innerHTML = "error " + this.getDateTime();
          }
        });
    }
  }

  getDateTime() {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;
    return dateTime;
  }
}
