import { Controller } from "stimulus";
import "popper.js";
// import "bootstrap";
import * as bootstrap from "bootstrap";
import select2 from "select2";
import flatpickr from "flatpickr";
import { it } from "flatpickr/dist/l10n/it.js";
import videojs from "video.js";

require("slick-carousel");

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:before-cache", () => {
      this.clearJS();
    });

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        container: "body",
        html: true,
        placement: "auto",
      });
    });

    $(".search-select").select2();

    $(".search-user-state").select2({
      placeholder: "Tipologia utente",
      templateSelection: function(selection, container) {
        if (selection.selected) {
          switch (selection.id) {
            case "member":
              container.addClass("bg-success text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
            case "guest":
              container.addClass("bg-danger text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
            case "to_be_approved":
              container.addClass("bg-warning text-dark");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-dark");
              break;
            case "admin":
              container.addClass("bg-light text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
            case "registered":
              container.addClass("bg-dark text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
          }
          return $.parseHTML(
            '<span class="im_selected">' + selection.text + "</span>"
          );
        } else {
          return $.parseHTML(
            '<span class="im_writein">' + selection.text + "</span>"
          );
        }
      },
    });

    $(".search-webinar-state").select2({
      placeholder: "Stato",
      templateSelection: function(selection, container) {
        if (selection.selected) {
          switch (selection.id) {
            case "open":
              container.addClass("bg-warning");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
            case "guest":
              break;
            case "draft":
              container.addClass("bg-secondary text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
            case "finished":
              container.addClass("bg-success text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
            case "closed":
              container.addClass("bg-dark text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
          }
          return $.parseHTML(
            '<span class="im_selected">' + selection.text + "</span>"
          );
        } else {
          return $.parseHTML(
            '<span class="im_writein">' + selection.text + "</span>"
          );
        }
      },
    });

    $(".search-webinar-attendee-state").select2({
      placeholder: "Status",
      templateSelection: function(selection, container) {
        if (selection.selected) {
          switch (selection.id) {
            case "payment_confirmed":
              container.addClass("bg-warning");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
            case "guest":
              break;
            case "waiting_for_payment":
              container.addClass("bg-danger text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
            case "waiting_for_test":
              container.addClass("bg-info text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
            case "finished":
              container.addClass("bg-success text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
            case "cancelled":
              container.addClass("bg-dark text-light");
              container
                .find(".select2-selection__choice__remove")
                .addClass("text-light");
              break;
          }
          return $.parseHTML(
            '<span class="im_selected">' + selection.text + "</span>"
          );
        } else {
          return $.parseHTML(
            '<span class="im_writein">' + selection.text + "</span>"
          );
        }
      },
    });

    flatpickr(".datepicker:not(.flatpickr-input)", {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      locale: "it",
    });

    flatpickr(".datepickerMin18:not(.flatpickr-input)", {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      locale: "it",
      maxDate: new Date().fp_incr(-6570),
    });

    flatpickr(".datetimepicker:not(.flatpickr-input)", {
      altInput: true,
      altFormat: "d/m/Y H:i",
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      locale: "it",
      time_24hr: true,
    });

    $("form .submit-on-change").on("change", function(event) {
      $(this)
        .closest("form")
        .submit();
    });

    $("form .submit-on-enter").keydown(function(event) {
      if (event.keyCode == 13) {
        $(this)
          .closest("form")
          .submit();
      }
    });

    $("form .submit-on-keyup").on("keyup", function(event) {
      $(this)
        .closest("form")
        .submit();
    });

    $(".no-propagation").click(function(e) {
      e.stopPropagation();
    });

    $(".webinar-exam-search-select").on("change", function(event) {
      $.get({
        url:
          "/admin/exams/" +
          $(this).val() +
          "/preview-partial?redirect_url=" +
          $("#update_redirect_path").val(),
        success: function(data) {
          $("#exam-preview").html(data);
        },
      });
    });

    this.saveTabSelection("#webinars-tab-select", "activeWebinarsTab");

    document.querySelectorAll("video-js").forEach((element) => {
      videojs(element);
    });

    // Home Carousels
    if ($(".active-category-carousel").length) {
      $(".active-category-carousel").slick({
        dots: false,
        arrows: true,
        infinite: true,
        draggable: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: $(".active-category-carousel--prev"),
        nextArrow: $(".active-category-carousel--next"),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              arrows: false,
            },
          },
        ],
      });
    }

    if ($(".events-carousel").length) {
      $(".events-carousel").slick({
        dots: false,
        arrows: true,
        infinite: true,
        draggable: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        prevArrow: $(".events-carousel--prev"),
        nextArrow: $(".events-carousel--next"),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              arrows: false,
            },
          },
        ],
      });
    }

    if ($(".focus-carousel").length) {
      $(".focus-carousel").slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
      });
    }

    $(".accordion-header").on("click", function(event) {
      if (!$(event.target).hasClass("bypassAccordion")) {
        event.preventDefault();
        $(this).toggleClass("open");
        $(this)
          .parents(".accordion-item")
          .find(".accordion-body")
          .toggleClass("show");
      }
    });

    // Attend course
    $(".close-lessons-panel").on("click", function(event) {
      event.preventDefault();

      if (!$(".lessons-list").hasClass("closed")) {
        $(".lessons-list").addClass("closed");
        //$(".lessons-list")

        // .removeClass("col-lg-3")
        // .addClass("col closed")
        // .css("width", 0);
        $(".lesson-stage")
          .removeClass("col-lg-9")
          .addClass("col-lg-12");
      } else {
        //$(this).removeClass("closed");
        $(".lessons-list").removeClass("closed");
        // .addClass("col-lg-3")
        // .attr("style", "");
        $(".lesson-stage")
          .removeClass("col-lg-12")
          .addClass("col-lg-9");
      }
    });

    $(".course-detail-tabs .nav-link").on("click", function(event) {
      event.preventDefault();
      $(".course-detail-content .tab-pane").removeClass("show active");
      $(
        '.course-detail-content .tab-pane[data-tab="' +
          $(this).attr("data-tab") +
          '"]'
      ).addClass("show active");
      $(".course-detail-tabs .nav-link").removeClass("active");
      $(this).addClass("active");
    });

    $(".course-detail-tabs .nav-item")
      .eq(0)
      .find(".nav-link")
      .trigger("click");

    $(".scrolltolink").on("click", function(event) {
      event.preventDefault();
      var target = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(target).offset().top - 120,
        },
        400
      );
    });

    $(".lesson-replacement").on("click", function(event) {
      event.preventDefault();
      var element = $(this)
        .closest(".card-body")
        .find(".replacement-container");
      if (element.hasClass("d-none")) {
        element.removeClass("d-none");
      } else {
        element.addClass("d-none");
      }
    });

    $(".cf-validation").on("focusin", function() {
      $(".cf-validation").removeClass("error");
      $(".cf-error").css("display", "none");
    });
    $("form.edit_user, form.new_user").on("submit", (event) => {
      if (
        $(".cf-validation").length &&
        this.validaCodiceFiscale($(".cf-validation").val()) == false &&
        $(".cf-validation").val() != ""
      ) {
        $(".cf-validation").addClass("error");
        $(".cf-error").css("display", "block");
        return false;
      } else {
        $(".cf-validation").removeClass("error");
        $(".cf-error").css("display", "none");
      }
    });

    $(".toggle-password").on("click", function(event) {
      // toggle the type attribute
      var $password = $(this)
        .parents(".form-group")
        .find("input");
      var type = $password.attr("type") === "password" ? "text" : "password";
      $password.attr("type", type);
      // toggle the eye slash icon
      $(this)
        .find("svg")
        .toggleClass("fa-eye fa-eye-slash");
    });

    // header search toggle
    $(".header-search-toggle").on("click", function(event) {
      event.preventDefault();
      $(".header-search-wrapper").toggleClass("show");
    });
    $(".header-search-close").on("click", function(event) {
      event.preventDefault();
      $(".header-search-wrapper").removeClass("show");
    });
  }

  saveTabSelection(parent, property) {
    $(parent + ' button[data-toggle="tab"]').on("show.bs.tab", function(e) {
      localStorage.setItem(property, $(e.target).attr("data-target"));
    });

    var activeWebinarsTab = localStorage.getItem(property);
    if (activeWebinarsTab) {
      $(parent + ' button[data-target="' + activeWebinarsTab + '"]').tab(
        "show"
      );
    }
  }

  validaCodiceFiscale(cf) {
    var validi, i, s, set1, set2, setpari, setdisp;
    if (cf == "") return "";
    cf = cf.toUpperCase();
    if (cf.length != 16) return false;
    validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (i = 0; i < 16; i++) {
      if (validi.indexOf(cf.charAt(i)) == -1) return false;
    }
    set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    s = 0;
    for (i = 1; i <= 13; i += 2)
      s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    for (i = 0; i <= 14; i += 2)
      s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    if (s % 26 != cf.charCodeAt(15) - "A".charCodeAt(0)) return false;
    return true;
  }

  clearJS() {
    $(".select2-hidden-accessible").select2("destroy");

    try {
      if ($(".active-category-carousel").length) {
        $(".active-category-carousel").slick("unslick");
      }
      if ($(".events-carousel").length) {
        $(".events-carousel").slick("unslick");
      }
      if ($(".focus-carousel").length) {
        $(".focus-carousel").slick("unslick");
      }
    } catch (error) {
      // console.error(error);
    }

    try {
      $(".datetimepicker")
        .flatpickr()
        .destroy();
    } catch (error) {
      // console.error(error);
    }
    try {
      $(".datepicker")
        .flatpickr()
        .destroy();
    } catch (error) {
      // console.error(error);
    }
    try {
      $(".datepickerMin18")
        .flatpickr()
        .destroy();
    } catch (error) {
      // console.error(error);
    }

    document.querySelectorAll("video-js").forEach((element) => {
      var video = videojs(element);
      video.dispose();
    });
  }
}
