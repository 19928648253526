import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["companyId"];

  connect() {
    // $(".search-select").on("select2:select", function () {
    //   let event = new Event("change", { bubbles: true }); // fire a native event
    //   this.dispatchEvent(event);
    // });

    $(".search-select").select2();

    $(".nested-fields .submit-on-change").on("change", function (event) {
      $("#add-lesson").val("true");
      $(this).closest("form").submit();
    });
  }

  // updateCompany() {
  //   let companyId = this.companyIdTarget.value;
  //   Turbo.visit(window.location.pathname + "?company_id=" + companyId);
  // }
}
