import { Controller } from "stimulus";
import "bootstrap";
// import $ from "jquery";

// require("select2/dist/css/select2");
// require("select2-bootstrap-theme/dist/select2-bootstrap");

import Select2 from "select2";

export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    var url = this.urlValue + ".json";
    $(".search-select-stimulus").select2({
      ajax: {
        url: url,
        data: function(params) {
          var query = {
            search: params.term,
            type: "public",
            page: params.page || 1,
          };

          // Query parameters will be ?search=[term]&type=public
          return query;
        },
      },
    });
  }
  disconnect() {}
}
