import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = [
    "eventType",
    "webinar",
    "inPresence",
    "otherPlatform",
    "streaming",
    "title",
    "eventTitle",
    "eventStartsAt",
    "eventDurationMinutes",
    "startsAt",
    "durationMinutes",
  ];

  connect() {
    $("#stimulus-event-form select").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    this.eventTypeChange();
    this.titleUpdate();
    this.startsAtUpdate();
    this.durationMinutesUpdate();
  }

  eventTypeChange() {
    let eventType = this.eventTypeTarget.value;
    console.log(eventType);
    if (eventType == "webinar") {
      this.webinarTarget.classList.remove("d-none");
      this.inPresenceTarget.classList.add("d-none");
      this.otherPlatformTarget.classList.add("d-none");
      this.streamingTarget.classList.add("d-none");
    } else if (eventType == "in_presence") {
      this.webinarTarget.classList.add("d-none");
      this.inPresenceTarget.classList.remove("d-none");
      this.otherPlatformTarget.classList.add("d-none");
      this.streamingTarget.classList.add("d-none");
    } else if (eventType == "streaming") {
      this.webinarTarget.classList.add("d-none");
      this.inPresenceTarget.classList.add("d-none");
      this.otherPlatformTarget.classList.add("d-none");
      this.streamingTarget.classList.remove("d-none");
    } else if (eventType == "other_platform") {
      this.webinarTarget.classList.add("d-none");
      this.inPresenceTarget.classList.add("d-none");
      this.otherPlatformTarget.classList.remove("d-none");
      this.streamingTarget.classList.add("d-none");
    } else {
      this.webinarTarget.classList.add("d-none");
      this.inPresenceTarget.classList.add("d-none");
      this.otherPlatformTarget.classList.add("d-none");
      this.streamingTarget.classList.add("d-none");
    }
  }

  titleUpdate() {
    let value = this.eventTitleTarget.value;
    this.titleTargets.forEach((element) => {
      element.value = value;
    });
  }
  startsAtUpdate() {
    let value = this.eventStartsAtTarget.value;
    this.startsAtTargets.forEach((element) => {
      element.value = value;
    });
  }
  durationMinutesUpdate() {
    let value = this.eventDurationMinutesTarget.value;
    this.durationMinutesTargets.forEach((element) => {
      element.value = value;
    });
  }
}
