import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["companyId"];

  connect() {

    $(".search-select").select2();

    $(".nested-fields .submit-on-change").on("change", function (event) {
      $("#add-course").val("true");
      $(this).closest("form").submit();
    });
  }

}
