import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["companyId"];

  connect() {
    $(".search-select").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
  }

  updateCompany() {
    let companyId = this.companyIdTarget.value;
    Turbo.visit(window.location.pathname + "?company_id=" + companyId);
  }
}
