// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("@fortawesome/fontawesome-free/js/all.js");
global.jQuery = require("jquery");
global.$ = require("jquery");
require("jquery-ui/ui/widgets/sortable");
require("src/sortable");
require("src/cocoon");
require("./vue_directives");
require("./edit_user_company");
require("./vue_forms");
require("./vue_course_form");
require("custom");
require("trix");
require("@rails/actiontext");
require("video.js");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import videojs from "video.js";

import "bootstrap";
// import "@fortawesome/fontawesome-free/css/all.css";
import "./stylesheets/application.scss";

import { Turbo } from "@hotwired/turbo-rails";
window.Turbo = Turbo;

document.addEventListener("turbo:load", () => {
  $('[data-toggle="tooltip"]').tooltip();

  window.setTimeout(function() {
    $(".alert-autohide")
      .fadeTo(500, 0)
      .slideUp(500, function() {
        $(this).remove();
      });
  }, 20000);
});
import "controllers";
