import { Controller } from "stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["details"];

  connect() {}

  showDetails(event) {
    event.currentTarget.parentElement
      .querySelector(".details")
      .classList.toggle("d-none");
    event.currentTarget
      .querySelectorAll(".chevrons svg, .chevrons i")
      .forEach((element) => {
        element.classList.toggle("d-none");
      });
  }
}
